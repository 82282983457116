.palma-heading {
	@include rfs(56);
	font-family: $font-f-header;
	font-weight: $font-w-heavy;
	margin-bottom: 2.75rem;
	margin-top: 3.95rem;

	&--small {
		@include rfs(24);
		margin-bottom: 1.15rem;
		margin-top: 1.05rem;
	}
}
.palma-heading--center {
	text-align: center;
}

.palma-heading__big {
	@include rfs(60);
	color: $color-heading;
	font-family: $font-f-content;
	font-weight: $font-w-black;
}

.palma-heading__small {
	@include rfs(16);
	color: $color-primary;
	font-family: $font-f-content;
	font-weight: $font-w-semibold;
	letter-spacing: .1em;
	text-transform: uppercase;
}



.palma-heading--h1 {
	@include rfs(48);
}
.palma-heading--h2 {
	@include rfs(42);
}
.palma-heading--h3 {
	@include rfs(32);
}
.palma-heading--h4 {
	@include rfs(28);
}
.palma-heading--h5 {
	@include rfs(22);
}
.palma-heading--h6 {
	@include rfs(18);
}