// Colors
$color-white: #ffffff;
$color-black: #000000;

$color-primary: #1ae4ab;
$color-primary-light: lighten($color-primary, 17%);

$color-background: $color-white;
$color-background-gray: #F7FAFC;
$color-content: #212121;
$color-heading: #233142;
$color-heading-light: #606060;

$color-nav-static: $color-content;
$color-nav-fixed: $color-white;

$color-border: #d0d0d0;
$color-border-light: #f0f0f0;

$color-input-background: $color-background-gray;
$color-input-icon: #798690;

$color-footer-background: #233142;

// Fonts
$font-f-content: 'Montserrat', Helvetica, Verdana, Arial, sans-serif;
$font-f-header: 'PlayFair Display', Georgia, serif;

$font-w-thin: 100;
$font-w-light: 200;
$font-w-book: 300;
$font-w-regular: 400;
$font-w-medium: 500;
$font-w-semibold: 600;
$font-w-bold: 700;
$font-w-black: 800;
$font-w-heavy: 900;


// Layout
$container-width: 1350px;
$negative-margin-max-width: 1132px;

$padding-h: 20px;


// Transition
$transition: .5s;
$ease: ease-in-out;


// Borders
$border-radius: 5px;


// Inputs
$input-border-radius: 5px;
$input-border-width: 1px;
$input-border-color: rgba(#a0a0a0, .3);
$input-border-color-2: rgba(#b0b0b0, .3);
$input-border-color-3: rgba(#c0c0c0, .3);

$input-min-height: 45px;
$input-max-height: 52px;


// Negative block
$negative-block-sm: -135px;
$negative-block-md: -135px;
$negative-block-lg: -135px;


// RFS
$rfs-breakpoint: 1600px;
$rfs-factor: 7.5;