.form-control {
    @include rfs(14);
    color: #9c9b9b;
    font-weight: $font-w-semibold;

    border-radius: $input-border-radius;
    border: 1px solid rgba($color-content, 0);
    background: $color-input-background;

    min-height: $input-min-height;
    height: vw($input-max-height);
    max-height: $input-max-height;

    padding: 6px 14px;

    transition: all $transition $ease;
    text-overflow: ellipsis;

    &:focus,
    &:hover {
        border: 1px solid rgba($color-content, .3)
    }


    &--icon {
        padding-right: 3.5rem;
    }
}