.header-rmsearch-container {
    display: block;

    &::after {
        $shadow: 0 -90px 250px 35px rgba(#000, .25);

        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 7%;
        right: 7%;
        border: 0;
        height: 1px;
        box-shadow: $shadow;
    }
}

.header-rmsearch-outer {
    background: $color-white;
    border-radius: $border-radius;
    min-height: 150px;

    padding: 1.25rem 1.45rem;
    position: relative;
    z-index: 5;
}


.roomSearch {}
.roomSearch__wrapper {
    display: flex;
    flex-direction: column;
}
.roomSearch__group {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .85rem;
}
.roomSearch__icon {
    fill: $color-primary;
    margin: 0 .55em 0 0;
    width: 50px;
    height: 35px;

    &--over {
        fill: $color-input-icon;
        position: absolute;
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        width: 20px;
        height: 20px;
    }
}
.roomSearch__group--small {
    flex: .4;
    justify-content: flex-end;
}

.roomSearch__inputWrapper {
    position: relative;
    width: 100%;
}

.roomSearch__heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}


.roomSearch__offline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: .5rem;
    padding-bottom: .5rem;
}
.roomSearch__offline .palma-heading {
    margin-top: 0;
    margin-bottom: 1.3rem;
}


@media screen and (max-width: 767px) {
    .header-rmsearch-container {
        display: block;
    }

    .header-rmsearch-outer {
        padding: 1.75rem;
    }
    
    .roomSearch__group {
        padding: vw(25px);
        margin-bottom: vw(65px);
    }
    .roomSearch__group [class*="icon-"]::before {
        margin-left: 0;
    }

    .roomSearch__icon {
        display: none;
    }
}


@media screen and (min-width: 768px) {
    .header-rmsearch-outer {
        padding: 1.25rem 2.5rem;
    }
    .roomSearch__wrapper {
        flex-direction: row;
    }
}


@media screen and (min-width: 1200px) {
    .roomSearch__group {
        padding: 1.45rem;
    }
}