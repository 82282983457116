.palmaNav__menuList {
    margin: 0;
    padding: 0;
}
.palmaNav__item {
    display: flex;
    position: relative;
}
.palmaNav__link {
    display: flex;
    align-items: center;

    font-weight: 600;
    text-decoration: none;

    transition: color $transition $ease;
}


/* < 768px */
@media screen and (max-width: 767px) {
    $mobile-link-height: 50px;

    .palmaNav__menu {
        background: $nav-color-background-expanded;

        position: absolute;
        top: calc(100% + 1px);
        left: 0;
        right: 0;

        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        max-height: 0;
        overflow-y: auto;
        
        transition: all $transition $ease;
    }

    .palmaNav--expanded .palmaNav__menu {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        min-height: $nav-height;
        max-height: calc(100vh - #{$nav-height});
    }

    .palmaNav--expanded.palmaNav--fixed .palmaNav__menu {
        max-height: calc(100vh - #{$nav-fixed-height});
    }

    .palmaNav__menuList {
    }

    .palmaNav__item:not(:first-child) {
        border-top: 1px solid rgba($color-primary, .25);
    }
    .palmaNav__item--basket {
        border-top: 0;
    }
    .palmaNav__item--basket > a {
        display: none;
    }

    .palmaNav__link {
        color: $color-primary;
        min-height: $mobile-link-height;
        width: 100%;

        padding-left: $padding-h;
        padding-right: $padding-h;

        &:hover,
        &:focus {
            background: rgba($color-primary, .1);
            color: $color-primary-light;
        }
    }
}


/* >= 768px */
@media screen and (min-width: 768px) {
    .palmaNav__menu {
        display: flex;
        position: static !important;
    }

    .palmaNav__menuList {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .palmaNav__link {
        color: $color-white;

        padding-left: calc(6px + .25vw);
        padding-right: calc(6px + .25vw);

        &:hover,
        &:focus {
            color: mix($color-white, $color-primary, 85%);
            text-decoration: none;
        }
    }
    .palmaNav__item:last-child .palmaNav__link {
        padding-right: 0;
    }
    .palmaNav--fixed .palmaNav__link {
        color: $color-primary;
    }


    // layer cart
    #layer_cart {
        $shadow: 0 -90px 250px 35px rgba(#000, .25);
        
        border-radius: $border-radius;
        width: 80% !important;
        min-width: 450px;
        max-width: 900px;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden;

        transition: all $transition $ease;

        box-shadow: $shadow;
    }
    #layer_cart .layer_cart_success {
        .icon-check {
            color: $color-primary;
            margin-right: .5rem;
        }
    }
    #layer_cart .product-image-container {
        border-radius: $border-radius;
        overflow: hidden;
    }
}


/* >= 1200px */
@media screen and (min-width: 1200px) {
    .palmaNav__link {
        padding-left: calc(8px + .6vw);
        padding-right: calc(8px + .6vw);
    }
}