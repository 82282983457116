$btn-min-width:                     120px;
$btn-min-height:                    $input-min-height;
$btn-max-height:                    $input-max-height;

$btn-color-background:              $color-primary;
$btn-color-background-hover:        $color-primary-light;
$btn-color-text:                    $color-white;

$btn-text-weight:                   $font-w-semibold;

$btn-border-width:                  0;
$btn-border-radius:                 $input-border-radius;

.btn {
    @include rfs(14);

    display: inline-flex;
    justify-content: center;
    align-items: center;

    min-width: 120px;
    
    min-height: $btn-min-height;
    height: vw($btn-max-height);
    max-height: $btn-max-height;
    
    background-color: $btn-color-background;
    border-radius: $btn-border-radius;
    
    color: $btn-color-text;
    font-weight: $btn-text-weight;

    padding-left: 20px;
    padding-right: 20px;
    margin-top: 2px;
    margin-bottom: 2px;

    transition: background-color $transition $ease,
        color $transition $ease;

    &:focus,
    &:hover {
        background-color: $btn-color-background-hover;
        color: $btn-color-text;
    }

    &:focus {
        outline: 1px solid darken($btn-color-background, 10%);
    }
}

.btn:disabled,
.btn--disabled {
    $disabled-background: desaturate($btn-color-background, 100%);

    &, &:hover, &:focus {
        background-color: $disabled-background;
    }
    &:focus {
        outline: 1px solid darken($disabled-background, 10%);
    }
}

.btn--gray {
    $color-background: #eff1f3;
    $color-text: #233142;

    background-color: $color-background;
    color: $color-text;

    &:focus,
    &:hover {
        background-color: darken($color-background, 10%);
        color: $color-text;
    }
}

.btn--transparent {
    background-color: transparent;
    color: $color-border;

    &:focus,
    &:hover {
        background-color: transparent !important;
        color: darken($color-border, 8%);
    }
}


.btn--small {
    $multiplier: .75;

    min-width: ($btn-min-width * $multiplier);
    
    min-height: ($btn-min-height * $multiplier);
    height: vw($btn-max-height * $multiplier);
    max-height: ($btn-max-height * $multiplier);

    padding-left: 15px;
    padding-right: 15px;

}


.btn > [class^="icon-"],
.btn > [class*=" icon-"] {
    font-size: 1.3em;
    margin-right: 15px;
}