$nav-height: 110px;
$nav-fixed-height: 60px;

$nav-color-background: transparent;
$nav-color-background-expanded: rgba($color-white, .9);

@import 'nav_hamburger';
@import 'nav_menu';

.palmaNav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    max-height: $nav-height;
    z-index: 10000;
    
    display: flex;
    justify-content: flex-start;
    
    background-color: $nav-color-background;
    border-bottom: 1px solid transparent;

    transition: all $transition $ease;
}
.palmaNav--fixed {
    $color: mix($color-primary, $color-white, 75%);

    max-height: $nav-fixed-height;
}
.palmaNav--fixed {
    background-color: $nav-color-background-expanded;
    border-bottom: 1px solid mix($color-primary, $color-white, 25%);

    &::before {
        opacity: 1;
    }
}


.palmaNav__wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    max-width: 1350px;
    padding-left: $padding-h;
    padding-right: $padding-h;
}



/* Logo */
.palmaNav__logo {
    @include rfs(24);

    display: flex;
    justify-content: center;
    align-items: center;
    
    font-family: 'Merriweather', serif;
    font-weight: 900;

    a {
        color: $color-white;
        text-decoration: none;

        transition: color $transition $ease;
    }
}
.palmaNav--fixed {
    .palmaNav__logo a {
        color: $color-primary;

        &:hover,
        &:focus {
            color: $color-primary-light;
        }
    }
}


/* < 768px */
@media screen and (max-width: 767px) {
    .palmaNav--expanded {
        background-color: $nav-color-background-expanded;
        border-bottom: 1px solid mix($color-primary, $color-white, 25%);
    
        &::before {
            opacity: 1;
        }
    }
}


/* >= 768px */
@media screen and (min-width: 768px) {
}