.site-header {
    width: 100%;
    position: relative;
}
.site-header__inner {
    background-size: cover;
    background-repeat: no-repeat;

    min-height: 780px;
    height: vw(890px);
    max-height: 890px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: calc(50px + 10vw);
    padding-top: calc(30px + 10vw);

    &::before {
        $mask-color: rgba( lighten( saturate($color-primary, 20%), 10%), .75 );

        $mask-gradient: linear-gradient(
            to top,
            rgba( saturate($color-primary, 10%), .3),
            rgba( saturate($color-primary, 2%), .1)
        );

        content: '';
        display: block;
        z-index: 0;
        background-image: $mask-gradient;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
    }

    &::after {
        content: '';
        display: block;
        background-image: url('../img/palma/header-mask.svg');
        background-size: 105% auto;
        background-position: bottom center;
        background-repeat: no-repeat;
        display: block;
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
    }
}

.site-header__content {
    color: $color-white;
    width: 70%;
    max-width: 800px;
    text-align: center;
    text-shadow: 0 0 16px rgba(#000, .3);
}

.site-header__heading {
    @include rfs(56);
    font-family: $font-f-header;
    font-weight: $font-w-heavy;
}

.site-header__followup {
    @include rfs(20);
    line-height: 1.4;
    font-weight: $font-w-medium;
    margin: 1.2rem 0;
    text-shadow: 0 0 12px rgba(#000, .3);
}


.header-negative-top {
    width: 80%;
    max-width: $negative-margin-max-width;
    margin-left: auto;
    margin-right: auto;
    margin-top: $negative-block-sm;
    position: relative;
    z-index: 100;
}

.header-page {
    background: $color-white;
    border-radius: $border-radius;
}


@media screen and (min-width: 768px) {
    .header-negative-top {
        margin-top: $negative-block-md;
    }
}


@media screen and (min-width: 1200px) {
    .header-negative-top {
        margin-top: $negative-block-lg;
    }
}


@media screen and (min-width: 1700px) {
    .mainCart {
        right: calc(-670px + 50vw) !important;
    }
}