%link-primary {
    color: $color-primary;
    font-weight: $font-w-semibold;
    transition: color $transition $ease;
    
    &:focus,
    &:hover {
        color: $color-primary-light;
    }
}


%after-shadow {
    position: relative;

    &::after {
        $shadow: 0 -90px 250px 35px rgba(#000, .25);

        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 7%;
        right: 7%;
        border: 0;
        height: 1px;
        box-shadow: $shadow;
    }
}