.footer-container {
    background: $color-footer-background;
    color: $color-white;
    padding: vw(20px) 0 vw(60px);
}

.palma-footer {
    color: $color-white;
    display: flex;
    flex-direction: column;
}


.footer-nav {
    margin-bottom: 1rem;
}
.footer-nav__heading {
    @include rfs(24);
    font-weight: $font-w-heavy;
    margin-bottom: 1.1rem;
    margin-top: 1.9rem;
    text-align: left;
}
.footer-nav__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: left;
}
.footer-nav__item {
    @include rfs(18);
    margin-bottom: .65rem;

    a {
        color: #f7fafc;

        &:focus,
        &:hover {
            color: #ffffff;
        }
    }
}


.footer-info {
}
.footer-info__heading {
    @include rfs(18);
    font-weight: $font-w-bold;
}
.footer-info__address {
    @include rfs(18);

    a { color: $color-white; }
}



.palma-footer__authors {
    display: flex;
    align-items: center;
    margin: .75rem 0;

    a {
        display: block;
    }
}


.palma-footer__social {
    margin: .65rem 0 .6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        color: $color-white;
        font-size: 24px;
        padding: .65rem;
    }
}



@media screen and (max-width: 539px) {
    .palma-footer__info,
    .palma-footer__nav,
    .palma-footer__authors,
    .palma-footer__social {
        text-align: center;
    }
}


@media screen and (min-width: 540px) {
    .palma-footer {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .footer-nav {
        display: flex;
        flex-direction: row;
        width: 60%;
    }
    .footer-nav__wrapper {
        width: 50%;
        margin-right: 1rem;
    }

    .footer-info {
        width: 40%;
        text-align: right;
    }
    .footer-info__heading {
        margin-top: 2.05rem;
    }

    .palma-footer__authors {
        display: flex;
        align-items: center;
    }

    
    .palma-footer__social {
        justify-content: flex-end;
        margin-left: auto;
    }


    .palma-footer__authors,
    .palma-footer__social {
        margin-top: 2rem;
    }
}

