$hamburger-size: 36px;
$hamburger-dash-size: 18px;

$hamburger-dash-offset: 5px;

.palmaNav__hamburger {
    background: rgba($color-white, 0);
    border: 2px solid $color-white;
    width: $hamburger-size;
    height: $hamburger-size;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-direction: column;

    transition: all $transition $ease;
}
.palmaNav__hamburger > span {
    display: block;
    background: $color-white;
    width: $hamburger-dash-size;
    height: 2px;

    transition: all $transition $ease;
}
.palmaNav__hamburger > span:nth-child(1) {
    transform: translateY(-#{$hamburger-dash-offset});
}
.palmaNav__hamburger > span:nth-child(3) {
    transform: translateY(#{$hamburger-dash-offset});
}

.palmaNav__hamburger:hover,
.palmaNav__hamburger:focus {
    background: rgba($color-white, .3);
    border: 2px solid $color-primary-light;
    outline: 1px solid rgba($color-primary-light, .5);
}
.palmaNav__hamburger:hover > span,
.palmaNav__hamburger:focus > span {
    background: $color-primary-light;
}

.palmaNav--expanded,
.palmaNav--fixed {
    .palmaNav__hamburger {
        border: 2px solid $color-primary;

        & > span {
            background: $color-primary;
        }
    }

    .palmaNav__hamburger:hover,
    .palmaNav__hamburger:focus {
        border: 2px solid $color-primary-light;
        outline: 1px solid rgba($color-primary-light, .5);

        & > span {
            background: $color-primary-light;
        }
    }
}

.palmaNav--expanded {
    .palmaNav__hamburger {
        & > span:nth-child(1) {
            transform: translateY(2px) rotate(135deg);
        }
        & > span:nth-child(2) {
            transform: scale(0);
            opacity: 0;
        }
        & > span:nth-child(3) {
            transform: translateY(-2px) rotate(-135deg);
        }
    }
}


/* < 768px */
@media screen and (max-width: 767px) {
}


/* >= 768px */
@media screen and (min-width: 768px) {
    .palmaNav__hamburger {
        display: none;
    }
}