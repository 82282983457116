// @font-face {
//     font-family: 'icomoon';
//     src:  url('../fonts/icomoon.eot?u9msui');
//     src:  url('../fonts/icomoon.eot?u9msui#iefix') format('embedded-opentype'),
//           url('../fonts/icomoon.ttf?u9msui') format('truetype'),
//           url('../fonts/icomoon.woff?u9msui') format('woff'),
//           url('../fonts/icomoon.svg?u9msui#icomoon') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

// [class^="icon-"], [class*=" icon-"] {
//     font-family: 'icomoon' !important;
//     speak: none;
//     font-style: normal;
//     font-weight: normal;
//     font-variant: normal;
//     text-transform: none;
//     line-height: 1;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }

// .icon-home:before {
//     content: "\e900";
// }
// .icon-newspaper:before {
//     content: "\e904";
// }
// .icon-pencil:before {
//     content: "\e905";
// }
// .icon-image:before {
//     content: "\e90d";
// }
// .icon-camera:before {
//     content: "\e90f";
// }
// .icon-phone:before {
//     content: "\e942";
// }
// .icon-pushpin:before {
//     content: "\e946";
// }
// .icon-location:before {
//     content: "\e947";
// }
// .icon-compass2:before {
//     content: "\e94a";
// }
// .icon-map2:before {
//     content: "\e94c";
// }
// .icon-mobile:before {
//     content: "\e958";
// }
// .icon-user:before {
//     content: "\e971";
// }
// .icon-spinner2:before {
//     content: "\e97b";
// }
// .icon-key2:before {
//     content: "\e98e";
// }
// .icon-glass2:before {
//     content: "\e9a1";
// }
// .icon-spoon-knife:before {
//     content: "\e9a3";
// }
// .icon-bin2:before {
//     content: "\e9ad";
// }
// .icon-sphere:before {
//     content: "\e9c9";
// }
// .icon-eye:before {
//     content: "\e9ce";
// }
// .icon-heart:before {
//     content: "\e9da";
// }
// .icon-man:before {
//     content: "\e9dc";
// }
// .icon-woman:before {
//     content: "\e9dd";
// }
// .icon-man-woman:before {
//     content: "\e9de";
// }
// .icon-question:before {
//     content: "\ea09";
// }
// .icon-plus:before {
//     content: "\ea0a";
// }
// .icon-minus:before {
//     content: "\ea0b";
// }
// .icon-cross:before {
//     content: "\ea0f";
// }
// .icon-checkmark:before {
//     content: "\ea10";
// }
// .icon-facebook2:before {
//     content: "\ea91";
// }
// .icon-twitter:before {
//     content: "\ea96";
// }
// .icon-exit:before {
//     content: "\ea14";
// }

