.mainCart,
.mainCart__wrapper,
.mainCart__inner,
.mainCart__content,
.mainCart__content > .block_content,
.mainCart .cart_block_list {
    display: flex;
}


.mainCart__inner {
    width: 100%;
}
.mainCart__inner > .cart_block_list {
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.mainCart {
    width: auto;
}

.mainCart__header {
    @include rfs(18);
    border-top: 1px solid rgba(#404040, .15);
    border-bottom: 1px solid rgba(#404040, .2);
    color: $color-heading-light;
    font-weight: $font-w-heavy;
    padding-left: $padding-h;
    padding-right: $padding-h;
    padding-top: 1.25rem;
    padding-bottom: .5rem;
}

.mainCart__content {
    width: 100%;
}
.mainCart__content > .block_content {
    flex-direction: column;
    margin-bottom: 0;
}

.mainCart__buttons {
    margin-top: auto !important;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
}

.mainCart .product_info_data {
    font-weight: $font-w-bold;
}

.mainCart .cart_prod_cont {
    @include rfs(11);
}


@media screen and (max-width: 767px) {
    .mainCart {
        width: 100%;
    }
    .mainCart__wrapper {
        flex-direction: column;
    }
}


@media screen and (min-width: 768px) {
    .mainCart {
        background: $color-white;
        position: absolute;
        top: calc(100% + 1px); 
        right: 0;

        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        z-index: -1;
        max-height: 0;
        min-width: 320px;
        max-width: 400px;

        transition: all $transition $ease;
    }

    .mainCart--expanded {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        z-index: 1;
        max-height: 500px;
    }

    .mainCart__header {
        display: none;
    }

    .mainCart .cart_block_list {
        min-height: 200px;
    }
}